.document-grouping-container {
  display: flex;
  flex-direction: column;
}

.sm-back-button {
  margin-top: 0.5rem;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sm-back-button-text {
  color: #1e407d;
}

.page-chip[data-status=true] {
  cursor: pointer;
  width: 98%;
  background-color: inherit;
  border: 1px solid #2F5394;
  border-radius: 5px;
  font-weight: 500;
}

.page-chip {
  cursor: pointer;
  width: 98%;
  display: flex;
}

.document-chip {
  width: 98%;
  height: fit-content;
  background-color: #F2F4F7;
  border-radius: 5px;
}

.document-chip-page-range {
  width: 98%;
  height: fit-content;
  background-color: #D1E9FF;
  border-radius: 5px;
}

.attachment-chip {
  width: 98%;
  height: fit-content;
  background-color: #D0D5DD;
  border-radius: 5px;
}

.attachment-chip-page-range {
  width: 98%;
  height: fit-content;
  background-color: #B2DDFF;
  border-radius: 5px;
}


