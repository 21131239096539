:root {
  --color-text-primary: #102449;
  --color-text-secondary: #032c54;

  --color-divider-main: rgb(0, 0, 0, 0.12);
  --color-border-grey-main: #d0d5dd;
  --color-primary-main: #102449;
  --color-primary-main-light: #385d9f;
  --color-selected-grey-main: #f0f0f0;
  --color-medium-grey-main: #aebbca;

  --color-checkbox-blue: #375d9f;
  --color-action-blue: #1e407d;
  --color-link-blue: #007bff;
  --color-label-blue: #385d9f;

  --color-chip-bg: #ebebeb;
  --color-chip-button: #adb6c1;
  --color-chip-text: #000000de;

  --color-icon-grey: #475467;

  --color-cancel-red: #f05353;

  --color-scroller-document-group-blue: #cbd5e0;
  --color-scroller-attachment-group-blue: #b2ddff;
}

.sm-horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-divider-main);
  border: 0;
}

.sm-button {
  font-family: 'Plus Jakarta Sans';
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid var(--color-border-grey-main);
  padding: 0.5rem 1rem;
}

.sm-button-primary {
  background-color: var(--color-action-blue);
  font-family: 'Plus Jakarta Sans';
  border-radius: 8px;
  border: none;
  box-shadow: none;
  color: white;
  padding: 0.5rem 1rem;
}

.sm-button-primary:hover {
  filter: brightness(0.9);
}

.sm-checkbox {
  accent-color: var(--color-checkbox-blue);
  border: 1px solid var(--color-checkbox-blue);
  color: white;
  cursor: pointer;
}

.borderless {
  border: none;
}

.sm-button:hover {
  filter: brightness(0.9);
}

.sm-icon-button {
  font-family: 'Plus Jakarta Sans';
  border-radius: 8px;
  box-shadow: none;
  border: none;
  padding: 0.5rem;
  background-color: unset;
}

.sm-icon-button:hover {
  filter: brightness(0.8);
}

.sm-translucent {
  opacity: 50%;
}

.sm-translucent:hover {
  opacity: 100%;
}

.sm-disabled {
  opacity: 50%;
  cursor: not-allowed;
  pointer-events: none;
}

.sm-header {
  font-size: 1.4rem;
  color: #385d9f;
  display: inline-block;
  font-weight: 700;
}

.sm-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  border-color: rgba(224, 224, 224, 1);
  border: 1px solid rgba(224, 224, 224, 1);
}

.sm-table th {
  background-color: rgba(248, 250, 251, 0.38);
  font-weight: bold;
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.sm-table td {
  padding: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: white;

  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.sm-table td:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.sm-textfield {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid grey;
}

.sm-textfield:focus {
  outline: 2px solid grey;
}

.sm-inputlabel {
  color: #385d9f;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 0.8rem;
}
