.sm-dropdown-menu-container {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 16px;
  background-color: white;
  margin-top: 5px;
  border: 1px solid;
  border-color: var(--color-border-grey-main);
  inset: 0px auto auto 0px;
  position: absolute;
  z-index: 1000;
}

.sm-dropdown-menu-item {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid var(--color-divider-main);
  cursor: pointer;
  font-size: 0.8rem;
}

.sm-dropdown-menu-item-disabled {
  padding: 10px;
  background-color: var(--color-selected-grey-main);
  border-bottom: 1px solid var(--color-divider-main);
  font-size: 0.8rem;
}

.sm-dropdown-menu-item:hover {
  background-color: var(--color-selected-grey-main);
}
