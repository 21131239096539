@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css';
.MuiAccordionSummary-root.Mui-focused {
  background-color: unset !important;
}
.MuiAccordion-root:before {
  display: none;
}
#launcher {
  bottom: -5px !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
#root {
  height: 100vh;
}

.auth-container {
  padding: 16px;
  text-align: center;
}

.auth-input {
  width: 3ch;
  padding: 8px;
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
  margin-right: 7px;
  border: 1px solid white;
  text-transform: uppercase;
  background-color: #e3e3e3;
}

.searchbar > .MuiInputBase-root {
  border-radius: 18px;
  background: rgba(229, 234, 248, 0.5);
}

.files-table-header-row {
  background-color: #e5eaf840;
  font-weight: 600;
  padding: 1rem;
  opacity: 90%;
  cursor: 'pointer';
}

.note-screenshot-rotating-border {
  width: max-content;
  background: linear-gradient(90deg, #00dfa2 50%, transparent 50%),
    linear-gradient(90deg, #00dfa2 50%, transparent 50%),
    linear-gradient(0deg, #00dfa2 50%, transparent 50%),
    linear-gradient(0deg, #00dfa2 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y !important;
  background-size:
    15px 2px,
    15px 2px,
    2px 15px,
    2px 15px !important;
  padding: 10px;
  animation: border-dance 4s infinite linear;
  background-color: rgba(0, 0, 0, 0.1);
}

.side-nav-list::-webkit-scrollbar,
.pdf-display-list::-webkit-scrollbar {
  width: 0.8em;
}

.side-nav-list::-webkit-scrollbar-track,
.pdf-display-list::-webkit-scrollbar-track {
  background-color: white;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
}

.side-nav-list::-webkit-scrollbar-thumb,
.pdf-display-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

@keyframes border-dance {
  0% {
    background-position:
      0 0,
      100% 100%,
      0 100%,
      100% 0;
  }
  100% {
    background-position:
      100% 0,
      0 100%,
      0 0,
      100% 100%;
  }
}
