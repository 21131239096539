.sm-grouping-toolbar-container {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  justify-content: center;
}

.sm-translucent {
  opacity: 50%;
}

.sm-translucent:hover {
  opacity: 100%;
}

.sm-grouping-buttons-container .sm-button {
  margin-right: 1rem;
  margin-left: 1rem;
}

.sm-button-text {
  font-size: 14px;
  font-weight: 600;
}

.sm-button > * {
  vertical-align: middle;
}

.sm-button-icon {
  margin-right: 1rem;
}
