.dropdown {
  user-select: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 40px;
  overflow: hidden;
  border-radius: 10px;
}

.dropdown-selectedValues {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: space-around;
}

.dropdown-menuList {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: scroll;
  border-radius: 16px;
  background-color: white;
  margin-top: 5px;
  border: 1px solid var(--color-border-grey-main);
  inset: 0px auto auto 0px;
  position: absolute;
  z-index: 1000;
}

.dropdown-menuItem {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid var(--color-divider-main);
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.dropdown-menuItem:hover {
  filter: brightness(0.9);
}

.dropdown-menuSelected {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid var(--color-divider-main);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  opacity: 50%;
}

.dropdown-header {
  font-size: 0.8rem;
  padding: 1px;
  display: inline-flex;
  font-weight: 400;
}

.dropdown-icons {
  cursor: pointer;
  display: flex;
  align-items: center; 
  justify-content: center;
}