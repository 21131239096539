.blur-switch-container {
  font-size: 0.7rem;
  margin-right: 0.3rem;
  margin-top: 0.2rem;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.7rem;
  margin-left: 1.5rem;
}

.image-box {
  position: relative;
  display: inline-block;
  border-radius: 5px;
}

.images-container {
  display: flex;
  flex-direction: column;
}

.image-container-styles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  margin-top: 2.5rem;
  vertical-align: top;
}

.nav-buttons-container {
  display: flex;
  white-space: nowrap;
  margin-right: 5rem;
}

.toolbar-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: right;
  align-items: center;
  width: fit-content;
  height: 24px;
}

.select-all-button {
  font-size: 0.7rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
  white-space: no-wrap;
}

.sm-back-button {
  margin-top: 0.5rem;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.sm-back-button-image-tab {
  margin-left: 0.5rem;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.sm-back-button-text {
  color: #1e407d;
}

.zoom-level-styles {
  border: 1px solid #d0d5dd;
  border-right: 0;
  font-size: 0.68rem;
  font-weight: 600;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 1.6rem;
  height: 1.6rem;
}
