.chip {
  display: inline-block;
  border-radius: 8px;
  padding: 0.25rem;
  border: 1px solid;
  max-height: 25px;
  display: flex;
  align-items: center;
}

.chip-label {
  color: var(--color-chip-text);
  font-size: 0.65rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.chip-button {
  display: inline-block;
  margin-left: 0.25rem;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
}

.chip-button:hover {
  cursor: pointer;
  filter: brightness(0.9);
}
