.details-box {
  border-radius: 10px;
  padding: 16px;
}

.details-box-row {
  display: flex;
  flex-direction: row;
  padding: 8px 16px 8px 16px;
}

.details-card {
  background-color: white;
  border-radius: 10px;
  padding: 16px;
}

.details-header {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  color: #101828;
}

.details-label {
  font-family: Inter;
  font-size: 13.5px;
  font-weight: 600;
  color: #101828;
}

.details-chip {
  padding: 2px 5px 2px 5px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}

.details-value {
  font-family: Inter;
  font-size: 13.5px;
  font-weight: 400;
  color: #475467;
  overflow: visible;
  white-space: wrap;
}

.tag-counts-box {
  background-color: white;
  border-radius: 10px;
  margin-top: 16px;
  padding: 16px;
  height: fit-content;
}

.tag-count-number {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  color: #101828;
}

.dialog-text {
  font-size: 0.8rem;
  font-weight: 500;
  margin: auto;
  margin-left: 0;
}

.dialog-box-row {
  display: flex;
  flex-direction: row;
  padding: 4px;
}

.dialog-label {
  font-family: Inter;
  font-size: 13.5px;
  font-weight: 600;
  color: #101828;
  padding-right: 4px;
}