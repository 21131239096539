body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
h1 {
  margin-top: 0;
}
html {
  width: 100%;
  height: 100%;
  display: table;
  overflow: hidden;
}

body {
  width: 100%;
  display: table-cell;
}

html,
body {
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
}

.scrollable-viewer {
  overflow-x: scroll;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.viewer-buttons-container {
  position: fixed;
  margin-top: 5rem;
  align-items: center;
  z-index: 1000;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  background-color: #f9fafb;
  border: 1px solid #d0d5dd80;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 6px;
  border-radius: 7px;
  padding-right: 12px;
  opacity: 0.6;
}

.viewer-buttons-container:hover {
  opacity: 1;
}

.viewer-buttons-container-rotations {
  position: fixed;
  z-index: 1000;
  margin-right: 10px;
  gap: 5px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  padding: 6px;
  border-radius: 7px;
  padding-right: 0px;
  padding-left: 7px;
  opacity: 0.6;
}

.viewer-buttons-container-rotations:hover {
  opacity: 1;
}

.nav-button {
  background-color: white;
  border-radius: 9px;
  border: 1px solid #a6a8ac80;
  padding-left: 12px;
  padding-right: 12px;
  height: 27px;
  font-size: 13px;
  font-weight: 600;
}

.nav-button:focus {
  outline: 1px solid #80818480;
}
