.input-container {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-border-grey-main);
  border-radius: 8px;
  line-height: 1;
}

.input-icon {
  position: absolute;
  left: 10px;
  z-index: 10;
}

.input {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border: none;
  outline: none;
}
