.sm-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sm-modal-content {
  background: white;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
  z-index: 2;
  overflow-y: auto;
  padding: 0;
  border: none;
  max-height: 95%;
  max-width: calc(95% - 40px);
  margin-left: 80px;
}
