.dropdown {
  user-select: none;
  width: 100%;
}

.dropdown-select {
  padding: 10px;
  width: 85%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: small;
  border: 0.5px solid rgb(219, 219, 219);
  border-radius: 10px;
}

.dropdown-item {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid var(--color-divider-main);
  cursor: pointer;
  font-size: small;
}

.dropdown-item:hover {
  background-color: var(--color-selected-grey-main);
}

.dropdown-list {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 16px;
  background-color: white;
  margin-top: 5px;
  border: '1px solid var(--color-border-grey-main)';
  inset: 0px auto auto 0px;
  position: absolute;
  z-index: 1000;
}